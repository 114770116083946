<!-- 哈四定制 -->
<template>
    <div class="study-list-view-container">
        <div class="container is-vertical">
            <div class="header" style="height: 40vmin">
                <div class="hospital" style="height: 20vmin;">
                    <img class="hospitalIconUrl" :src="hospitalIconUrl" alt="">
                </div>
                <div class="userinfo">
                    <div class="container">
                        <div class="main">
                            <img class="user_icom" src="../../assets/pati-img.png" alt="" srcset="">
                            <span class="patientName">{{patientName}}</span>
                            <span class="patientGender">{{patientGender}}</span>
                            <span class="patientAge">{{patientAge}}</span>
                        </div>
                        <div class="aside" style="width: 33vmin">
                            <img style="width: 0px" class="user_icom" src="../../assets/user.png" alt="" srcset="">
                          <span class="cuont">检查总数：<span style="font-weight: bold">{{cuont}}</span>条</span>
                        </div>
                    </div>
                </div>
                <div class="listname">
                    检查列表
                </div>
            </div>
            <div class="main">
                <div v-if="loading" class="loading">加载中...</div>

                <div class="study-list-group" v-for="(value,key) in studyList" :key="key">
                    <div class="date">{{ key }}</div>
                    <div
                        class="study-list-item"
                        v-for="study in value"
                        :key="study.risStudyId"
                        @click="clickStudy(study.risStudyId)"
                    >
                        <div class="study-info">
                            <div>
                                <span class="study-item-title">检查时间：</span>
                                <span class="study-info-time">{{ study.time }}</span>
                                <span class="study-info-deviceType">{{ study.deviceType }}</span>
                                <span class="study-info-imageCount">{{ study.imageCount }}图</span>
                            </div>
                            <div>
                                <span class="study-item-title">检查项目：</span>
                                <span class="study-item" >{{study.pacsStudyItem}} </span>
                            </div>
<!--                            <div v-if="study.displayEflimImageFlag!=1">-->
<!--                                <div class="study-item payflag" v-if="isShowFeeTip(study)">注：电子胶片未缴费，只能浏览报告</div>-->
<!--                            </div>-->
                            <div class="study-item payflag" v-if="study.imageCount == 0">注：此部位图像请在其他同时期检查中查看。</div>
                        </div>
                        <div class="icon-box">
                            <div class="arrow-icon"><i class="el-icon-arrow-right"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { MessageProxy } from '@/utils/MessageProxy';
import { store } from '@/utils/store';

export default {
    name: 'StudyListView',
    components: {
    },
    // props: {
    //     patientName: String
    // },
    data() {
        return {
            loading: true,
            hospitalIconUrl: '',
            cuont: 0,
            studyList: [],
            selectedStudyId: "",
            studyViewVisible: false,
            patientName: "",
            patientGender: "",
            patientAge: "",
            maintenanceModeFlag:false,
            enableImageWhenReportUnaudit:false
        }
    },
    created() {
        this.patientName =  this.$route.query.patientName;
        this.patientGender =  this.$route.query.patientGender;
        this.patientAge =  this.$route.query.patientAge;
        this.getStudyList();
        this.hospitalIconUrl = `${store.imageUrl}logo_${store.hospital.shortName}.png`;
        this.maintenanceModeFlag = store.maintenanceModeFlag;
        this.enableImageWhenReportUnaudit = store.enableImageWhenReportUnaudit;
    },
    methods: {
        getStudyList () {
            let queryType = "";
            let queryCondition = {};
            queryType = "searchStudy";
            queryCondition = {
                userId: store.userId,
                patientName: this.$route.query.patientName,
                queryKey : this.$route.query.queryKey,
                hospitalId: store.hospital.id || this.$route.query.hospitalId
            }

            this.loading = true
            // 获取StudyList
            MessageProxy.getStudyList(queryType, queryCondition).then((result)=>{
                this.loading = false
                if (true == result.hasError) {
                    this.$message.error(result.errorText);
                    this.$router.go(-1);
                    return;
                }
                if (result.data != null && result.data.code != '200') {
                    this.$message.error(result.data.message);
                    this.$router.go(-1);
                    return;
                }
                this.cuont = result.data.data.length;
                this.studyList = this.group(result.data.data);
		if ( store.wxOpenId && this.studyList) {
                //if (this.studyList) {
                    let data = {
                        hospitalId: store.hospital.id || this.$route.query.hospitalId,
                        patient_name: queryCondition.patientName,
                        patient_no: queryCondition.queryKey,
                        openid: store.wxOpenId
                    }

                    const arr = []
                    for (let key in data){
                        arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
                    }

                    MessageProxy.addPatient(data).then((result)=>{
                        console.log('addPatient result:', result)
                    })

                    MessageProxy.addPatientWeChat(arr.join('&')).then((result)=>{
                        console.log('addPatient result:', result)
                    })
                }
            })
        },
        isShowFeeTip(study){
            if (this.maintenanceModeFlag == true) {
                return false        // 运维模式下，不显示未交费提示
            }

            if (store.verifyPaymentStatusFlag == true){
                if (study.displayEflimImageFlag == undefined || study.displayEflimImageFlag!=1){
                    return true     // 费用验证模式下，当云胶片标志为null，或不为1时，显示未缴费提示
                }
            }

            return false
        },
        sortFn(arr) {
            // 排序
            arr.sort(function (param1, param2) {
                param1 = param1['studyTime'];
                param2 = param2['studyTime'];
                if (typeof param1 == 'string' && typeof param2 == 'string') {//如果两个参数均为字符串类型
                    return param1.localeCompare(param2);
                }
                if (typeof param1 == 'number' && typeof param2 == 'string') {//如果参数1为数字，参数2为字符串
                    return -1;
                }
                if (typeof param1 == 'string' && typeof param2 == 'number') {//如果参数1为字符串，参数2为数字
                    return 1;
                }
                if (typeof param1 == 'number' && typeof param2 == 'number') {//如果两个参数均为数字
                    if (param1 > param2) { return 1; }
                    if (param1 == param2) { return 0; }
                    if (param1 < param2) { return -1; }
                }
            });
            return arr
        },
        group (arr) {
            // 合并分组
            let obj = {};
            // 倒叙
            arr = this.sortFn(arr).reverse()
            arr.forEach(el => {
                // var studyDate = new Date(el.studyTime).Format('yyyy-MM-dd')
                var year = new Date(el.studyTime).getFullYear();
                var month = new Date(el.studyTime).getMonth() + 1;
                var day = new Date(el.studyTime).getDate();
                var studyDate = year + '年' + month + '月' + day + '日'

                el.time = new Date(el.studyTime).Format('hh:mm:ss')
                if (obj[studyDate]) {
                    obj[studyDate].push(el)
                } else {
                    obj[studyDate] = [el]
                }
            });
            for( let key in obj) {
                obj[key] = this.sortFn(obj[key])
            }
            return obj;
        },
        clickStudy(studyId) {
            this.selectedStudyId = studyId;
            this.$router.push('/studyinfoDetail/' + studyId);
        },
    }
}
</script>

<style scoped>
.container{
    display: flex;
    flex-direction: row;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-basis: auto;
    box-sizing: border-box;
    min-width: 0;
    height: 100%;
}
.container.is-vertical{
    flex-direction: column;
}
.header{
    box-sizing: border-box;
    flex-shrink: 0;
}
.main{
    display: block;
    flex: 1;
    flex-basis: auto;
    box-sizing: border-box;
}
.aside{
    flex-shrink: 0;
}
.loading{
    padding: 10vmin;
    text-align: center;
}
.study-list-view-container {
    position: relative;
    height: 100%;
    overflow-y: auto;
}
.header {
    background-image: url("../../assets/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.hospital{
    width: 100%;
    position: relative;
}
.hospitalIconUrl{
    max-height: 50%;
    max-width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
}
.userinfo{
    text-align: left;
    height: 10vmin;
    padding: 0 4vmin;
}
.user_icom{
    width: 7vmin;
    height: 7vmin;
    margin-right: 2vmin;
    position: relative;
    top: 1.5vmin;
}
.patientName{
    font-size: 5.2vmin;
  font-weight: bold;
}
.patientGender{
    margin: 0 2vmin;
  font-size: 4vmin;
}
.patientAge{
  font-size: 4vmin;
}
.cuont{
  font-size: 4vmin;
    /* float: right; */
}
.listname{
    height: 10vmin;
    line-height: 10vmin;
    text-align: left;
    padding: 0 6vmin;
    box-sizing: border-box;
    background: #fff;
    border-top-left-radius: 2vmin;
    border-top-right-radius: 2vmin;
    position: relative;
  font-size: 4vmin;
}
.listname::before{
    content: "";
    display: block;
    width: 5px;
    height: 40%;
    background: #303C47;;
    position: absolute;
    top: 30%;
    left: 3vmin;
    border-radius: 3px;
}
.study-list-group {
    margin-bottom: 1.5vmin;
}
.study-list-group .date {
    padding: 2vmin 3vmin;
    font-size: 4vmin;
  font-weight: bold;
    background: #f6f9ff;
    border-top: 1px solid #e7e7e7;
    text-align: left;
}

.study-list-item {
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #e7e7e7;
    color: #222;
    padding: 3vmin;
    text-align: left;
    font-size: 4vmin;
    display: flex;
    align-items: stretch;
}
.study-list-item:active {
    background-color: #eaeaea;
}
.study-info-deviceType{
    margin: 0 4vmin;
}
.study-list-item .study-info {
    flex-grow: 1;
    font-size: 4vmin;
    line-height: 1.5;
}
.study-list-item .icon-box {
    font-size: 8vmin;
    color: #ccc;

    display: flex;
    justify-content: center;
    align-items: center;
}
.study-list-item .study-hospital {
    font-size: 4.5vmin;
    color: #222;
    margin-bottom: 1.5vmin;
}
.study-list-item .report-diagnose {
    color: #555;
}
.study-list-item .study-item-title,
.study-list-item .diagnose-title {
    font-size: 4vmin;
    color: #888;
}
.study-list-item .image-count {
    background-color: #f6f6f6;
    padding: 1vmin 3vmin;
    border-radius: 5vmin;
}
.payflag{
    color: #CC0000;
}
.payflag.displayEflimImageFlag{
    color: #07b718;
}
.study-info-imageCount{
    margin: 0 4vmin;
}
.arrow-icon{
  font-size: 3.5vmin;
}
</style>
